<template>
  <div>
    <p class="has-text-grey">
      Vehicle overview
    </p>
    <div>
      <div class="columns is-multiline">
        <div class="column">
          <span class="title is-4">{{ description }} </span>
        </div>
      </div>
    </div>
    <Grid :data="grid" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MotorCheckSummary',
  components: {
    Grid: () => import('./MotorCheckGrid')
  },
  computed: {
    ...mapGetters('motorcheck', ['description', 'grid'])
  }
}
</script>
